.sj_slides.module {
	.sj-outer {
		position: relative;
		//border: 1px solid red;

		.sj-inner {
			position: sticky;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;

			.slide {
				width: 100vw;
				height: 100vh;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				opacity: 0;
				background: color(white);

				img,
				picture {
					width: 100%;
					height: auto;
				}

				&:not(:first-of-type) {
					opacity: 0;
				}
			}
		}
	}
}

.card_grid_section {
	background-image: url("../images/blue-camo.jpg");
	background-size: cover;
	background-position: center center;
	@include paddingTopBottom();
	margin-bottom: $paddingMain;

	h2 {
		color: color(white);
		text-align: center;
		max-width: 550px;
		margin: 0 auto $paddingMain;

		@media (max-width: bp(nav)) {
			margin: #{$gap * 4} auto 0 auto;
		}
	}

	img,
	picture {
		width: 100%;
		height: auto;
		max-width: 80px;
		margin: 0 auto #{$gap * 1};
	}

	.card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: $paddingMain;
		border-bottom: 2px solid color(white, transparent);

		@media (max-width: bp(nav)) {
			padding: #{$gap * 4} 0;
		}

		&:nth-of-type(odd) {
			border-right: 2px solid color(white, transparent);

			@media (max-width: bp(nav)) {
				border-right: 0;
			}
		}

		&:nth-of-type(3),
		&:nth-of-type(4) {
			border-bottom: 0;
		}

		&:nth-of-type(3) {
			@media (max-width: bp(nav)) {
				border-bottom: 2px solid color(white, transparent);
			}
		}
	}

	p.strong {
		color: color(accent, yellow);
		text-transform: uppercase;
		margin: 0;
	}

	p {
		color: color(white);
		text-align: center;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	// animations
	h2,
	.card-layout .card * {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
	}

	@for $i from 1 through 10 {
		.card-layout .card:nth-child(#{$i}) * {
			transition-delay: #{0.35 * $i}s;
		}
	}

	&.viewed {
		h2,
		.card-layout .card * {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.general_section {
	@include paddingTopBottom();

	img,
	picture {
		max-width: 100%;
		width: 100%;
		height: auto;
	}

	.link-intro {
		@include h3-font-styles();
		color: color(accent);
		max-width: 750px;
		margin: 0 auto;
		display: block;
		text-align: center;
	}

	p.title3 {
		font-weight: 200;
		text-transform: inherit;
		line-height: calc(57 / 31);
	}

	.desc {
		p:last-of-type {
			margin-bottom: 0;
		}

		a {
			color: color(accent, darkBlue);
		}
	}

	.desc.underline {
		border-bottom: 1px solid color(grey);
		padding-bottom: $paddingMain;
		margin-bottom: $paddingMain;

		@media (max-width: bp(nav)) {
			padding-bottom: $paddingMainMobile;
			margin-bottom: $paddingMainMobile;
		}
	}

	.btn {
		margin-top: #{$paddingMain * 1/3};
	}

	// animations
	p.title3,
	.desc,
	.link-intro,
	.btn {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
	}

	@for $i from 1 through 10 {
		*:nth-child(#{$i}) {
			transition-delay: #{0.3 * $i}s;
		}
	}

	&.viewed {
		p.title3,
		.desc,
		.link-intro,
		.btn {
			opacity: 1;
			transform: translate(0, 0);
		}

		.btn {
			transition: background-color $animationDuration, color $animationDuration,
				transform $animationDuration;

			&:hover {
				//background-color: color(accent);
				//color: color(white);
				transform: scale(1.05);
			}
		}
	}
}

/****************************************

FIXED HEADER

****************************************/
#wpadminbar {
	@media (max-width: bp(sm)) {
		display: none !important;
	}
}

html {
	@media (max-width: bp(sm)) {
		margin-top: 0 !important;
	}
}


.banner-legal {
  background-color: color(accent, base);;
  color: color(white);
  font-size: 10px;
  line-height: 1.1em;
  padding: 5px 0;
  text-align: center;
  position: relative;
  z-index: 9999999;
}

.banner-legal .container {
  padding: 0 10px;
}

@media screen and (min-width: 800px) {
  .banner-legal {
      font-size: 11px;
      padding: 10px 0;
  }
}

header {
	width: 100%;
	z-index: 99999;
	display: flex;
	flex-direction: column;
	position: relative;
	background: color(white);
	transition: all 0.5s;

	&.scroll,
	&.drop-in {
		position: fixed;
		top: 0;
		animation-name: down;
		animation-duration: 1.5s;
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
	}

	@keyframes down {
		from {
			top: -400px;
		}
		to {
			top: 0;
		}
	}

	@media (max-width: bp(nav)) {
		display: block;
		padding: 15px 10px;

		&.scroll {
			min-height: 50px;
			padding: 10px;
		}
	}

	.top-bar,
	.topbar {
		width: 100%;
		padding-top: #{$gap * 2};

		@media (max-width: bp(nav)) {
			padding: #{$gap * 2} 0;
			margin: #{$gap * 2} 0;
			border-top: 2px solid color(white, transparent);
			border-bottom: 2px solid color(white, transparent);
			display: flex;
			flex-wrap: wrap;
		}

		.flex-center {
			justify-content: flex-end;
		}

		p {
			@include p-disclaimer();
			margin: 0 #{$gap * 2} 0 0;

			a {
				text-decoration: none;
			}

			@media (max-width: bp(nav)) {
				color: color(white);
				margin-bottom: #{$gap * 2};

				a {
					color: color(white);
				}
			}
		}

		a.btn {
			font-size: 1.5rem;
			padding: 7px 15px;

			&:nth-of-type(2) {
				background-color: color(accent, lightBlue);
			}

			&:not(:last-of-type) {
				margin-right: #{$gap * 2};
			}
		}
	}

	.top-bar {
		@media (max-width: bp(nav)) {
			display: none;
		}
	}

	.menu-toggle {
		width: 70px;
		height: 70px;
		padding: 20px;
		display: none;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		cursor: pointer;
		transition: all 0.5s;

		@media (max-width: bp(nav)) {
			display: flex;
			width: 20px;
			height: 20px;
			padding: 0;
			margin-right: 10px;
			z-index: 9999;
		}

		.line {
			border-bottom: 1px solid color(accent);
			width: 25px;
			height: 1px;
			position: relative;
			display: block;
			transition: all 0.5s;
		}
	}

	.logo {
		width: 250px;
		display: block;
		margin-right: $paddingMain;
		flex: none;

		@media (max-width: bp(nav)) {
			width: 175px;
		}

		img,
		picture {
			width: 100%;
			height: auto;

			&.white-logo {
				display: none;
			}
		}
	}

	.inner {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: #{$gap * 2} 0;

		@media (max-width: bp(nav)) {
			justify-content: space-between;

			flex-wrap: wrap;
			flex-direction: row-reverse;
			padding: 0;
		}
	}

	.cont {
		@media (max-width: bp(nav)) {
			width: 100%;
			margin: 0;
		}
	}

	.menu-main-menu-container {
		width: 100%;
	}

	nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		@media (max-width: bp(nav)) {
			width: 100vw;
			height: 100vh;
			background: color(accent);
			top: 0;
			left: 0;
			position: fixed;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			max-height: 0px;
			overflow: hidden;
			opacity: 0;
			pointer-events: none;
			transition: all 0.5s;
			flex: none;
			padding: 65px #{$paddingMainMobile * 3/2} 0;

			&.active {
				max-height: 900px;
				padding-bottom: 10px;
        margin-top: 32px;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			justify-content: flex-end;

			@media (max-width: bp(nav)) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin: 0;
				padding: 0;
			}

			li {
				position: relative;
				margin-bottom: 0 !important;
				display: flex;
				align-items: center;

				@media (max-width: bp(nav)) {
					margin-bottom: #{$paddingMainMobile * 3/2} !important;

					&.no-link {
						a {
							pointer-events: none;
						}

						li a {
							pointer-events: auto;
						}
					}
				}

				a {
					font-size: 1.7rem;
					line-height: 1.2;
					text-decoration: none;
					text-transform: uppercase;
					font-weight: bold;
					color: color(accent);
					transition: color 0.5s;

					&:hover {
						color: color(accent, lightBlue);
					}

					&:after {
						content: "";
						width: 100%;
						height: 1px;
						background: color(grey);
						display: block;
					}

					@media (max-width: bp(nav)) {
						text-align: left;
						width: 100%;
						color: color(white);
						font-size: 2.4rem;
					}
				}

				&.menu-item-has-children {
					position: relative;

					&.active {
						.sub-menu {
							max-height: 1000px;
							padding: 10px 0 0 0 !important;
						}
					}
				}

				&.current-menu-ancestor,
				&.current-menu-item {
					a {
						color: color(accent, lightBlue);
					}

					.sub-menu {
						a {
							color: color(accent, dark);
						}

						.current-menu-item {
							a {
								color: color(accent);
							}
						}
					}
				}

				&.menu-item-has-children {
					&:hover {
						.sub-menu {
							opacity: 1;
							pointer-events: auto;
						}
					}

					.sub-menu {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						pointer-events: none;
						left: auto;
						right: calc((100% - 150px) / 2);
						top: 20px;
						padding: 20px;
						width: 150px;
						opacity: 0;
						background: color(white);
						position: absolute;
						transition: opacity 0.5s;

						@media (max-width: bp(nav)) {
							opacity: 1;
							flex-direction: column;
							max-height: 0px;
							width: 100%;
							position: relative;
							right: auto;
							top: auto;
							margin: 0;
							padding: 0 !important;
							transition: max-height 0.5s, padding 0.5s;
						}

						li {
							margin: 0;
							width: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 0;

							@media (max-width: bp(nav)) {
								flex-direction: column;
								padding: 0px !important;
								border-top: none;
								width: 100%;
							}

							a {
								width: auto;
								padding: 0;
								text-align: center;
							}

							&.current_page_item {
								a {
									color: color(accent);
								}
							}
						}
					}
				}

				&:not(:last-of-type) {
					margin-right: #{$gap * 4};
				}
			}
		}
	}

	&.active {
		@media (max-width: bp(nav)) {
			background: #fff;
		}

		.menu-toggle {
			justify-content: center;
			margin-bottom: 0;

			.line {
				border-bottom: 1px solid color(white);

				&:nth-of-type(3) {
					opacity: 0;
				}

				&:nth-of-type(1) {
					transform: rotate(45deg);
				}

				&:nth-of-type(2) {
					transform: rotate(-45deg) translate(1px, -1px);
				}
			}
		}

		.logo {
			z-index: 9999;

			img,
			picture {
				display: none;

				&.white-logo {
					display: block;
					z-index: 9999;
				}
			}
		}

		nav {
			position: fixed;
			pointer-events: auto;
			opacity: 1;
		}
	}

	&.scroll {
		.logo {
			width: 250px;
			margin-top: -30px;

			@media (max-width: bp(nav)) {
				width: 175px;
			}
		}

		.top-bar {
			padding-top: #{$gap};
		}

		.inner {
			padding: 10px 0;
		}

		nav ul li.menu-item-has-children:hover .sub-menu {
		}
	}
}

body.scroll {
	padding-top: 140px;
}

.contact_form,
.hubspot_form {
  @include paddingTopBottom;
  padding-top: 0 !important;

  .flex-center {
    align-items: flex-start;

    .col {
      &:nth-of-type(1) {
        width: 66.66%;
        padding-right: $paddingMain;

        @media (max-width: bp(nav)) {
          width: 100%;
          padding-right: 0;
          margin-bottom: #{$gap * 4};
        }
      }

      &:nth-of-type(2) {
        width: 33.33%;

        @media (max-width: bp(nav)) {
          width: 100%;
          margin-bottom: #{$gap * 4};
        }
      }
    }
  }

  p.title,
  p strong {
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1em;
  }

  p.title {
    margin-top: 2em;
  }

  .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    flex: none;
    margin-left: $gap;

    li {
      width: 20px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-of-type) {
        margin-right: 15px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img,
        picture {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  textarea {
    min-height: 150px;
  }
}

.eligibility_form {
  .small-cont {
    @include paddingTopBottom();
    border-top: 2px solid color(grey, underlines);
  }

  .form-group {
    max-height: 500px;
    visibility: visible;
    transition: visibility $animationDuration, margin $animationDuration,
      max-height $animationDuration;
    &.hidden {
      max-height: 0;
      margin: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}

.eligibility_form,
.contact_form {
  label,
  span {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
    color: color(accent);
    margin-bottom: 0.5em;
  }

  .contact-cont {
    label:not(.top-label) {
      display: none;
    }

    .form-group {
      margin-bottom: 10px;
    }

    .top-label {
      margin-bottom: 0.5em;
      display: flex;
    }
  }

  p {
    line-height: 1.4;
    font-size: 1.6rem;
    max-width: 550px;
  }
}

.form-group {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: #{$gap * 4};
  }

  p {
    font-size: 1.6rem;
  }

  select,
  textarea,
  input {
    font-family: $bodyFont;
    background: #eff2f8;
    border-radius: 5px;
    border: 1px solid color(accent, lightBlue);
    padding: 15px;
    font-size: 1.6rem;
    width: 100%;
    transition: border $animationDuration;
  }

  &.radio-btns {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 1em;
      }

      label {
        font-weight: 400;
        text-transform: none;
        margin: 0;
      }

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      .radio {
        width: 20px;
        font-family: $bodyFont;
        background: #eff2f8;
        border-radius: 50%;
        border: 1px solid color(accent, lightBlue);
        padding: 12px;
        font-size: 1.6rem;
        margin-right: 10px;
        transition: border $animationDuration;
      }

      input:checked ~ .radio:after {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: color(accent);
        opacity: 0.75;
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 9;
      }
    }
  }

  &.has-error {
    select,
    textarea,
    input {
      border-color: color(accent, red);
    }
  }

  .btn {
    border: none;
  }

  .help-block {
    margin: 0;
    text-align: left;

    li {
      font-size: 1.4rem;
      color: color(accent, red);
    }
  }
}

.gform_wrapper form {
  .gfield {
    input:not([type='radio']), select {
      font-family: Montserrat, sans-serif;
      background: #eff2f8;
      border-radius: 5px;
      border: 1px solid #5776af;
      padding: 15px;
      font-size: 1.6rem;
      width: 100%;
      transition: border 0.5s;
    }

    input[type='radio'] {
      appearance: none;
      background-color: #fff;
      margin: 0;
    }
    input[type='radio']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em black;
    }


    .gchoice {
      margin-bottom: 1em;

    }
    input[type='radio'] + label:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0px 8px 0 8px;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid black;
    }

    input[type='radio']:checked + label {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: 4px;
        width: 22px;
        height: 22px;
        border: 1px solid #5776af;;
        border-radius: 50%;
        display: inline-block;
        cursor: pointer;
      }

      &:before {
        background-color: #5776af;;
      }
    }
  }
}

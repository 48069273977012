/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
	padding: 10px 15px;
	font-size: 2rem;
	line-height: 1;
	font-weight: 600;
	border-radius: 50px;
	color: color(white);
	background-color: color(accent, red);
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	display: table;
	transition: background-color $animationDuration, color $animationDuration,
		transform $animationDuration;

	@media (max-width: bp(nav)) {
		font-size: 1.6rem;
	}

	&:hover {
		//background-color: color(accent);
		//color: color(white);
		transform: scale(1.05);
	}
}

.btn {
	@include btnStyle;

	&.center {
		margin: 0 auto;
		display: table;
		width: auto;
	}

	&.sml {
		font-size: 1.6rem;

		@media (max-width: bp(nav)) {
			font-size: 1.4rem;
		}
	}

	&.stars {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 320px;
		padding: 15px;

		&:before,
		&:after {
			content: "";
			width: 12px;
			height: 12px;
			flex: none;
			display: block;
			margin: 0 10px;
			background-image: url("../images/white-star.svg");
			background-size: 100% auto;
			background-repeat: no-repeat;

			@media (max-width: bp(nav)) {
				margin: 0 5px;
			}
		}
	}

	&.yellow {
		color: color(accent);
		background-color: color(accent, yellow);

		&.stars {
			&:before,
			&:after {
				background-image: url("../images/blue-star.svg");
			}
		}
	}

	&.white {
		color: color(accent);
		background-color: color(white);

		&.stars {
			&:before,
			&:after {
				background-image: url("../images/blue-star.svg");
			}
		}
	}
}

/****************************************

VARIABLES

****************************************/
@import "_functions";

// MSC
$closeDuration: 0.4s;
$fadeDuration: 0.6s;
$mobile: 768px;
$animationDuration: 0.5s;
$progressBarWidth: 36px;

//layout
$containerWidth: 1200px;
$sidebar: 360px;
$sidebarTablet: 265px;

$closeDuration: 0.4s;
$fadeDuration: 0.6s;
$mobile: 768px;
$sm: 576px; /* max sm is phones */
$md: 768px; /* max md is landscape phones */
$lg: 992px; /* max lg is tablets */
$xl: 1200px; /* max xl is desktop */

//fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,800;1,500;1,800&display=swap");

$headlineFont: "Montserrat", serif;
$bodyFont: "Montserrat", sans-serif;

//font sizes
$fontSizes: (
	h1: 6rem,
	h2: 5rem,
	h3: 4rem,
	h4: 3rem,
	h5: 2rem,
	p: 1.8rem,
	eyebrow: 1.4rem,
	disclaimer: 1.4rem,
	nav: 1.5rem,
	xlform: 5.2rem,
);

//break points
$breakPoints: (
	xs: 576px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1280px,
	xxl: 1400px,
	nav: 992px,
	tablet: 1024px,
	sm-desktop: 1200px,
	lg-desktop: 1400px,
);

//Spacing
$paddingMain: 6rem;
$paddingMainMobile: 2rem;
$gap: 10px;

$elementSpacingDesktop: 2rem;
@mixin elementSpacing {
	margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
	padding: $paddingMain;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile;
	}
}

@mixin paddingTopBottom {
	padding: $paddingMain 0;

	@media (max-width: bp(nav)) {
		padding: #{$paddingMainMobile * 3/2} 0;
	}
}

//colors
$colors: (
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0.5),
	),
	black: (
		base: #333133,
	),
	grey: (
		base: #dcddde,
		underlines: #f1f0ec,
	),
	accent: (
		base: #172a45,
		red: #b51737,
		lightBlue: #5776af,
		paleBlue: #eff2f8,
		yellow: #f7d152,
    darkBlue: #455e8b
	),
);

.overlay_image_section {
	&:after {
		content: "";
		width: 80%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-image: linear-gradient(
			to right,
			rgba(23, 42, 69, 0),
			rgba(23, 42, 69, 1)
		);
		z-index: 0;

		@media (max-width: bp(nav)) {
			display: none;
		}
	}

	.full {
		background-size: cover;
		background-position: left center;

		@media (max-width: bp(nav)) {
			background-image: none !important;

			.cont {
				width: 100%;
			}
		}
	}

	.flex-center {
		z-index: 1;
		position: relative;
		height: 800px;
		max-height: 1080px;
		justify-content: flex-end;

		@media (max-width: bp(nav)) {
			height: auto;
			background: color(accent);
		}

		.col {
			&:nth-of-type(1) {
				width: 70%;
				position: relative;
				background-size: cover;
				background-position: center center;

				@media (max-width: 1200px) {
					width: 55%;
				}

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				img,
				picture {
					width: 100%;
					height: auto;
					z-index: 1;
				}

				&:after {
					@media (max-width: bp(nav)) {
						content: "";
						width: 100%;
						height: 200px;
						position: absolute;
						bottom: 0;
						right: 0;
						background-image: linear-gradient(
							to bottom,
							rgba(23, 42, 69, 0),
							rgba(23, 42, 69, 1)
						);
						z-index: 0;
					}
				}
			}

			&:nth-of-type(2) {
				width: 35%;
				flex: none;

				@media (max-width: 1200px) {
					width: 45%;
				}

				@media (max-width: bp(nav)) {
					width: 100%;
					padding: 0 #{$paddingMainMobile * 3/2} #{$paddingMainMobile * 3/2};
					margin-top: -35px;
					z-index: 9;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				p,
				ul li {
					color: color(white);
				}

				h2 {
					padding-bottom: #{$gap * 4};
					margin-bottom: #{$gap * 4};
					border-bottom: 2px solid color(white, transparent);

					@media (max-width: bp(nav)) {
						padding-bottom: $paddingMainMobile;
						margin-bottom: $paddingMainMobile;
					}
				}
			}
		}
	}

	//animations
	&:after {
		width: 0;
		transition: width #{$animationDuration * 2};
	}

	.col:nth-of-type(2) {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
		transition-delay: 0.3s;
	}

	&.viewed {
		&:after {
			width: 80%;
		}

		.col:nth-of-type(2) {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

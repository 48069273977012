.general_slider {
	@include paddingTopBottom();

	.swiper-wrapper {
		align-items: stretch;

		.swiper-slide {
			height: auto;
			display: flex;
			align-items: center;
			background-color: color(accent);
			padding: $paddingMain;

			p {
				@include h3-font-styles();
				font-weight: bold;
			}

			.large-white {
				text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
			}
		}
	}

	.swiper-section {
		position: relative;
	}

	.swiper-cont {
		width: calc(100% - 100px);
		margin: 0 auto;

		@media (max-width: bp(nav)) {
			width: 100%;
			margin-bottom: 50px;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: flex;
		align-items: center;
		background: none;
		width: 50px;
		left: 0px;

		@media (max-width: bp(nav)) {
			top: calc(100% + 20px);
		}

		&:after {
			display: none;
		}

		img,
		picture {
			width: 100%;
			height: auto;
		}
	}

	.swiper-button-next {
		transform: rotate(180deg);
		left: auto;
		right: 0px;
	}
}

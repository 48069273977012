/****************************************

GLOBAL

****************************************/

/****************************************
REBASE
****************************************/
.debug-title {
	display: none;
}

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	font-size: 10px;
	color: color(black);
	font-family: $bodyFont;
	background-color: #fafaf9;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}
}

p,
h1,
h2,
h3,
h4 {
	margin: 0;
	padding: 0;
	color: color(black);
}

/****************************************
Responsive switches
****************************************/
.desktop {
	display: block;

	@media (max-width: bp(nav)) {
		display: none;
	}
}

.mobile {
	display: none;

	@media (max-width: bp(nav)) {
		display: block;
	}
}

/****************************************
Structural components
****************************************/
section,
.full {
	width: 100%;
	position: relative;
}

.lrg-cont {
	width: 1300px;
	margin: 0 auto;

	@media (max-width: bp(xl)) {
		width: 96%;
		margin: 0 2%;
	}
}

.cont {
	width: 1200px;
	margin: 0 auto;
	position: relative;
	display: block;

	@media (max-width: 1280px) {
		width: calc(100% - #{$paddingMain * 2});
		margin: 0 auto;
	}

	@media (max-width: bp(nav)) {
		width: calc(100% - #{$paddingMainMobile * 3});
		margin: 0 auto;

		&.no-mobile {
			width: 100%;
		}
	}
}

.small-cont {
	width: 900px;
	margin: 0 auto;

	@media (max-width: bp(md)) {
		width: calc(100% - #{$paddingMainMobile * 3});
		margin: 0 auto;
	}
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
	width: 100%;

	.video-size-frame {
		height: 0;
		padding-bottom: 56.3%;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/****************************************
quick show/hide layers
****************************************/
@mixin showLayer {
	position: relative;
	top: auto;
	width: 100%;
	pointer-events: auto;
	opacity: 1;
	z-index: 1;
}

@mixin hideLayer {
	position: absolute;
	width: 100%;
	opacity: 0;
	left: 0;
	pointer-events: none;
	z-index: -5;
}

/****************************************
flex layouts
****************************************/
@mixin flexCenter {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: bp(nav)) {
		flex-direction: column;
	}
}

.flex-center {
	@include flexCenter;

	.col {
		width: 100%;
		flex: 1 1 auto;
		flex-direction: column;
	}
}

.card-layout {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;

	&.halfs {
		.card {
			width: calc((100% - #{$gap * 2}) / 2);

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.thirds {
		.card {
			width: calc((100% - #{$gap * 4}) / 3);
			margin-bottom: #{$gap * 2};

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:nth-of-type(3n + 3)) {
				margin-right: #{$gap * 2};

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.quarters {
		.card {
			width: calc((100% - #{$gap * 6}) / 4);
			margin-bottom: #{$gap * 2};

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:nth-of-type(4n + 5)) {
				margin-right: #{$gap * 2};

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

/****************************************
ui basics
****************************************/
@mixin internalNav {
	@include flexCenter;
}

@mixin internalNavLink {
	width: 100%;
	flex: 1 1 auto;
	text-align: center;
}

/****************************************
UTILITY CLASSES
****************************************/
.relative {
	position: relative;
}
.nowrap {
  white-space: nowrap
}

/****************************************
NINJAFORMS REBASE
****************************************/
.form-cont {
	margin: 30px 0;

	.nf-form-content {
		padding: none;
		max-width: auto;
	}

	h3 {
		display: none;
	}

	nf-field {
		width: 100%;
		float: left;
		margin: 0 10px 10px 0;

		.nf-field-container {
			margin-bottom: 0;
		}

		input,
		textarea {
			padding: 10px;
			float: left;
			width: 100%;
			height: auto;
		}
	}

	.nf-field-label {
		//display: none;
	}

	.nf-form-content input[type="button"] {
		background-color: color(accent);

		&:hover {
		}
	}

	.nf-form-fields-required {
		display: none;
	}
}

/****************************************
FORMS - VALIDATOR
****************************************/
.has-error input,
.has-error select {
	border: 1px solid red;
}

.help-block {
	font-size: 12px;
	width: 100%;
	margin: 0 0 5px 0;
	text-align: center;
	padding: 0;
}

/****************************************
BACKGROUNDS
****************************************/
@mixin bgFill() {
	background-size: cover;
	background-position: center center;
}

.bg-fill {
	@include bgFill();
}

.bg-reveal {
	@include bgFill();
	background-attachment: fixed;
}

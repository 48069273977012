.tab_section {
	@include paddingTopBottom();

	.tab-nav {
		@include p-font-styles();
	}

	.tab-cont {
		.tab-item {
			display: flex;

			@media (max-width: bp(nav)) {
				flex-direction: column;
			}

			img,
			picture {
				width: 50%;
				height: auto;
				margin-right: $paddingMain;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-right: 0;
				}
			}
		}
	}
}

.team_leadership {
	.member {
		background-image: url("../images/blue-camo.jpg");
		background-size: cover;
		background-position: center center;
		@include paddingTopBottom();

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		ul li,
		a {
			color: color(white);
		}

		.headshot {
			width: 300px;
			height: auto;
			border-radius: 50%;
			overflow: hidden;
			margin-bottom: #{$paddingMain/2};

			img {
				width: 100%;
				height: auto;
			}

			@media (max-width: bp(nav)) {
				width: 150px;
				height: auto;
			}
		}

		.flex-center {
			.col {
				&:nth-of-type(1) {
					width: 300px;
					margin-right: $paddingMain;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					@media (max-width: bp(nav)) {
						width: 100%;
						margin-right: 0;
						align-items: flex-start;
					}

					.btn {
						margin-bottom: #{$paddingMain * 1/3};
					}

					a:not(.btn),
					p {
						@include p-font-styles();
						font-size: 1.6rem;
						margin: 0;
					}

					.titles {
						display: flex;
						flex-direction: column;
						align-items: center;

						@media (max-width: bp(nav)) {
							display: none;
						}
					}
				}

				&:nth-of-type(2) {
					width: calc(100% - 300px);

					@media (max-width: bp(nav)) {
						width: 100%;
					}

					.name {
						margin: 0 0 0.25em 0;
					}

					.title,
					.bio p {
						font-size: 1.6rem;

						@media (max-width: bp(nav)) {
							font-size: 1.4rem;
						}
					}

					.title {
						line-height: 1.2;

						@media (max-width: bp(nav)) {
							font-size: 1.2rem;
							margin-top: 5px;
						}
					}

					.titles {
						.btn {
							margin-bottom: #{$paddingMain * 1/3};
						}

						a,
						p {
							font-size: 1.4rem;
							margin: 0;
						}
					}
				}
			}
		}

		&:nth-of-type(odd) {
			background-image: url("../images/light-camo.jpg");

			p,
			a {
				color: color(black);
			}

			.name,
			.title {
				color: color(accent);
			}

			.btn {
				@extend .yellow.btn;
			}

			.flex-center {
				flex-direction: row-reverse;

				@media (max-width: bp(nav)) {
					flex-direction: column;
				}

				.col {
					&:nth-of-type(1) {
						margin-right: 0;
						margin-left: $paddingMain;

						@media (max-width: bp(nav)) {
							margin-left: 0;
						}
					}
				}
			}
		}

		// animations
		.col {
			opacity: 0;
			transform: translate(0, 50px);
			transition: opacity $animationDuration,
				transform #{$animationDuration * 2};

			&:nth-of-type(2) {
				transition-delay: 0.5s;
			}
		}

		&.viewed {
			.col {
				opacity: 1;
				transform: translate(0, 0);
			}
		}
	}
}

.multi-accordion_section {
	@include paddingTopBottom();

	h2 {
		padding-left: #{$gap * 2};

		@media (max-width: bp(nav)) {
			padding-left: 0;
		}
	}

	.accordion-section {
		.accordion-item {
			border-bottom: 2px solid color(grey, underlines);
		}

		.accordion-header {
			padding: #{$gap * 2};
			margin: 0;
			border-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width: bp(nav)) {
				padding: #{$gap * 2} 0;
			}

			p {
				text-transform: initial;
				color: color(accent, lightBlue);
				transition: color $animationDuration;
				font-weight: 600;

				@media (max-width: bp(nav)) {
					line-height: 1.3;
				}
			}

			.accordion-icon {
				width: 15px;
				height: 15px;
				border-left: 4px solid color(accent, lightBlue);
				border-top: 4px solid color(accent, lightBlue);
				display: flex;
				transform: rotate(-135deg);
				transition: border $animationDuration, transform $animationDuration;
			}
		}

		.accordion-details {
			padding: 0 #{$gap * 2};
			max-width: 1000px;

			@media (max-width: bp(nav)) {
				padding: 0;
			}
		}

		.open {
			.accordion-header {
				p {
					color: color(accent, red);
				}

				.accordion-icon {
					border-left: 4px solid color(accent, red);
					border-top: 4px solid color(accent, red);
					transform: rotate(45deg);
				}
			}

			.accordion-details {
				padding: 0 #{$gap * 2} #{$gap * 2};

				@media (max-width: bp(nav)) {
					padding: 0;
				}
			}
		}
	}

	// animations
	h2,
	.accordion-section .accordion-item {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
	}

	@for $i from 1 through 20 {
		.accordion-section .accordion-item:nth-child(#{$i}) {
			transition-delay: #{0.3 * $i}s;
		}
	}

	&.viewed {
		h2,
		.accordion-section .accordion-item {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.link_list_section {
	@include paddingTopBottom();

	ul.links {
		li {
			a {
				display: flex;
				text-decoration: none;

				@media (max-width: bp(nav)) {
					flex-direction: column;
				}
			}

			img,
			picture {
				width: 50%;
				height: auto;
				margin-right: #{$gap * 2};

				@media (max-width: bp(nav)) {
					width: 100%;
				}
			}
		}
	}
}

.hero_section {
	background: color(accent);

	.full {
		background-size: cover;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		@media (max-width: bp(nav)) {
			flex-direction: column-reverse;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		ul li {
			text-align: left;
			color: color(white);
		}

		h1 {
			max-width: 550px;
			margin-bottom: 0;
		}
	}

	.col {
		&:nth-of-type(1) {
			padding: $paddingMain $paddingMain $paddingMain calc((100vw - 1200px) / 2);

			@media (max-width: 1300px) {
				padding: $paddingMain;
			}

			@media (max-width: 1200px) {
				padding: #{$paddingMainMobile * 3/2};
			}

			//padding: $paddingMain;

			@media (max-width: bp(nav)) {
				z-index: 9;
				margin-top: -50px;
				padding: 0 #{$paddingMainMobile * 3/2} #{$paddingMainMobile * 3/2};
			}
		}

		&:nth-of-type(2) {
			position: relative;

			img,
			picture {
				width: 100%;
				height: auto;
				float: left;
			}

			&:before {
				content: "";
				top: 0;
				left: 0;
				width: 30%;
				height: 100%;
				display: block;
				position: absolute;
				background-image: linear-gradient(
					to right,
					rgba(23, 42, 69, 1),
					rgba(23, 42, 69, 0.75),
					rgba(23, 42, 69, 0)
				);

				@media (max-width: bp(nav)) {
					width: 100%;
					height: 100px;
					top: auto;
					bottom: 0;
					margin-bottom: -5px;

					background-image: linear-gradient(
						to top,
						rgba(23, 42, 69, 1),
						rgba(23, 42, 69, 0.75),
						rgba(23, 42, 69, 0)
					);
				}
			}
		}
	}

	//animations
	.col:nth-of-type(2):before {
		width: 10%;
		transition: width #{$animationDuration * 2};

		@media (max-width: bp(nav)) {
			height: 50px;
			width: 100%;

			transition: height #{$animationDuration * 2};
		}
	}

	h1 {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
		transition-delay: 0.3s;
	}

	&.viewed {
		.col:nth-of-type(2):before {
			width: 30%;

			@media (max-width: bp(nav)) {
				height: 100px;
				width: 100%;
			}
		}

		h1 {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.testimonials_slider {
	background-image: url("../images/light-camo.jpg");
	background-size: cover;
	background-position: center center;
	@include paddingTopBottom();

	&:before {
		content: "";
		width: 100%;
		height: 50%;
		position: absolute;
		top: 0;
		left: 0;
		background-image: linear-gradient(
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0)
		);
	}

	img,
	picture {
		border-radius: 50%;
		width: 275px;
		height: auto;
	}

	.title3 {
		color: color(accent, lightBlue);

		@media (max-width: bp(nav)) {
			margin: #{$paddingMainMobile * 3/2} 0 #{$gap * 1};
		}
	}

	.quote {
		font-style: italic;
	}

	.flex-center {
		.col {
			&:nth-of-type(1) {
				width: 275px;
				margin-right: $paddingMain;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: row;
				}

				img,
				picture {
					margin-bottom: #{$gap * 2};

					@media (max-width: bp(nav)) {
						width: 100px;
						height: auto;
						margin-right: #{$paddingMainMobile};
						margin-bottom: 0;
					}
				}

				p {
					text-align: center;
					font-weight: bold;
					margin: 0 0 0.35em 0;
					line-height: 1;
					font-size: 1.6rem;
					text-transform: uppercase;

					@media (max-width: bp(nav)) {
						text-align: left;
					}

					&.branch {
						font-style: italic;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			&:nth-of-type(2) {
				width: calc(100% - 275px);

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				.quote {
					@include h4-font-styles();
					font-weight: 100;

					p {
						@include h4-font-styles();
						text-transform: inherit;
						font-weight: 400;
						display: inline;
						margin-left: 2px;
					}
				}
			}
		}
	}

	.swiper-wrapper {
		align-items: stretch;

		.swiper-slide {
			height: auto;
			display: flex;
			align-items: center;
		}
	}

	.swiper-section {
		position: relative;
	}

	.swiper-cont {
		width: calc(100% - 100px);
		margin: 0 auto;

		@media (max-width: bp(nav)) {
			width: 100%;
			//margin-bottom: 50px;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: flex;
		align-items: center;
		background: none;
		width: 50px;
		left: 0px !important;

		@media (max-width: bp(nav)) {
			top: calc(100% + 20px);
			display: none;
		}

		&:after {
			display: none;
		}

		img,
		picture {
			height: 50px;
			width: auto;
		}
	}

	.swiper-button-next {
		transform: rotate(180deg);
		left: auto !important;
		right: 0px !important;
	}
}

/****************************************

FOOTER

****************************************/

footer {
	background-color: color(accent);
	width: 100%;
	@include paddingTopBottom();

	p,
	a {
		margin: 0;
		color: color(white);
	}

	.social {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		flex: none;
		margin-left: $gap;

		li {
			width: 20px;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:not(:last-of-type) {
				margin-right: 10px;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				img,
				picture {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	p.title {
		text-transform: uppercase;
		font-weight: bold;
	}



	.flex {
		display: flex;
		width: 100%;

		@media (max-width: bp(nav)) {
			flex-direction: column;
		}

		&:nth-of-type(1) {
			justify-content: flex-end;

			p,
			a {
				font-size: 1.6rem;
			}

			.col {
				width: 300px;
				flex: none;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-bottom: $paddingMain;
				}

				&:nth-of-type(1) {
					margin-right: calc(100% - 300px - 230px - 230px - #{$paddingMain});

					@media (max-width: bp(nav)) {
						margin-right: 0;
					}

					img,
					picture {
						max-width: 230px;
						width: 100%;
						height: auto;

						&.meridian {
							max-width: 275px;
						}
					}

					p {
						margin: #{$gap * 2} 0;
						font-size: 1.2rem;
					}
				}

				&:nth-of-type(2),
				&:nth-of-type(3) {
					width: 230px;
					flex: none;

					@media (max-width: bp(nav)) {
						width: 100%;
					}
				}

				.social {
					margin-left: 0;
					margin-top: #{$gap * 2};
					justify-content: flex-start;

					li {
						margin-right: #{$gap * 2};
					}
				}

				&:nth-of-type(3) {
					margin-left: $paddingMain;

					@media (max-width: bp(nav)) {
						margin-left: 0;
					}

					ul {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}

		&:nth-of-type(2) {
			justify-content: space-between;
			margin-top: $paddingMain;

			.col {
				display: flex;
				align-items: center;

				&:nth-of-type(1) {
					display: flex;
					align-items: center;

					@media (max-width: bp(nav)) {
						flex-direction: column;
						align-items: flex-start;
					}

					img,
					picture {
						width: 80px;
						height: auto;
						margin-right: #{$gap * 2};

						@media (max-width: bp(nav)) {
							margin-bottom: 10px;
						}
					}
				}
			}

			p {
				font-size: 1rem;
				text-transform: uppercase;
				margin: 0;

				@media (max-width: bp(nav)) {
					font-size: 0.9rem;
				}
			}
		}
	}
}

.team_map {
	@include paddingTopBottom();
	@media (max-width: bp(nav)) {
		padding-bottom: 0 !important;
	}

	h2 {
		text-align: center;
		max-width: 800px;
		margin: 0 auto;
		color: color(accent, lightBlue);
	}

	.map-cont {
		position: relative;
		width: 100%;
		display: table;

		#homepage_copy_9 {
			width: 100%;
			height: auto;

			@media (max-width: bp(nav)) {
				margin: #{$paddingMainMobile * 3/2} 0;
			}

			path {
				cursor: pointer;

				&.active {
					fill: #5775b2;
					//transform: scale(1.05);
					transition: transform $animationDuration, fill $animationDuration;
				}
			}
		}
	}

	&.viewed .map-cont #homepage_copy_9 path.hover {
		fill: #5775b2;
		transition: fill $animationDuration;
	}

	.card {
		width: 50%;
		max-width: 625px;
		position: absolute;
		top: 5%;
		right: 5%;
		opacity: 0;
		pointer-events: none;
		background: color(accent, paleBlue);
		border-radius: 10px;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
		padding: #{$gap * 2};
		transition: max-height $animationDuration, opacity $animationDuration;

		@media (max-width: bp(nav)) {
			max-width: 100%;
			position: relative;
			top: auto;
			right: auto;
			max-height: 0px;
			width: 100%;
			padding: 0 #{$paddingMainMobile * 3/2};
			overflow: hidden;
			border-top: 1px solid color(accent, lightBlue);
			border-radius: 0;
			box-shadow: none;
		}

		.close {
			position: absolute;
			top: 20px;
			right: 10px;
			font-size: 48px;
			line-height: 0;
			cursor: pointer;
		}

		&.active {
			opacity: 1;
			pointer-events: auto;

			@media (max-width: bp(nav)) {
				max-height: 1500px;
				padding: #{$paddingMainMobile * 3/2};
			}
		}

		.headshot {
			width: 150px;
			height: auto;
			border-radius: 50%;
			margin-bottom: #{$paddingMain * 1/3};
			overflow: hidden;

			img {
				width: 100%;
				height: auto;
			}
		}

		.flex-center {
			flex-direction: row-reverse;
			align-items: center;

			@media (max-width: bp(nav)) {
				flex-direction: column;
			}
		}

		.col {
			&:nth-of-type(1) {
				width: 200px;
				margin-left: #{$gap * 3};
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-left: 0;
					align-items: flex-start;
				}
			}

			.titles {
				display: flex;
				align-items: center;
				flex-direction: column;

				&.mobile {
					display: none;

					@media (max-width: bp(nav)) {
						display: block;
					}
				}

				&.desktop {
					display: flex;

					@media (max-width: bp(nav)) {
						display: none;
					}
				}

				.btn {
					margin-bottom: #{$paddingMain * 1/3};

					&:before,
					&:after {
						margin: 0 5px;
					}
				}

				a:not(.btn),
				p {
					@include p-font-styles();
					font-size: 1.2rem;
					margin: 0;
					color: color(black);

					@media (max-width: bp(xl)) {
						font-size: 1rem;
					}
				}
			}

			&:nth-of-type(2) {
				width: calc(100% - 150px);

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				.name {
					margin: 0 0 0.25em 0;
					text-transform: uppercase;
					line-height: 1;
				}

				.title,
				.bio p {
					font-size: 1.4rem;
				}

				p:last-of-type {
					margin-bottom: 0;
				}

				.title {
					line-height: 1.5;
					margin-bottom: 1em !important;
				}
			}
		}

		&.right {
			right: auto;
			left: 5%;

			.flex-center {
				flex-direction: row;

				@media (max-width: bp(nav)) {
					flex-direction: column;
				}

				.col {
					&:nth-of-type(1) {
						margin-left: 0;
						margin-right: #{$gap * 3};

						@media (max-width: bp(nav)) {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	// animations

	#homepage_copy_9 path {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
	}

	@for $i from 1 through 70 {
		#homepage_copy_9 path:nth-child(#{$i}) {
			transition-delay: #{0.02 * $i}s;
		}
	}

	&.viewed {
		#homepage_copy_9 path {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

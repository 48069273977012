.two_column_section {
	margin-bottom: $paddingMain;

	.flex-center {
		align-items: stretch;

		.col {
			width: 50%;

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:nth-of-type(1) {
				position: relative;
				background-size: cover;
				background-position: center center;
				min-height: 450px;

				@media (max-width: bp(xl)) {
					min-height: inherit;
				}

				img,
				picture {
					width: 100%;
					height: auto;
					z-index: 1;
				}

				h2 {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: color(white);
					text-align: center;
					padding: #{$paddingMain} 200px;
					flex-direction: column;
					font-size: 5rem;

					@media (max-width: bp(nav)) {
						font-size: 4rem;
						padding: #{$paddingMainMobile * 3/2} #{$gap * 3};
					}

					span {
						font-size: 2.8em;
						line-height: 0.85;
					}
				}
			}

			&:nth-of-type(2) {
				padding: $paddingMain;
				background-color: color(accent, paleBlue);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@media (max-width: bp(nav)) {
					padding: #{$paddingMainMobile * 3/2};
				}

				p {
					font-size: 1.6rem;

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	//animations
	h2,
	p {
		opacity: 0;
		transform: translate(0, 50px);
		transition: opacity $animationDuration, transform #{$animationDuration * 2};
	}

	.col:nth-of-type(2) {
		p {
			transition-delay: 0.3s;
		}
	}

	&.viewed {
		h2,
		p {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

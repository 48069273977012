$height: 600px;

.hero_slider {
	background: color(accent);

	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	ul li {
		text-align: center;
		color: color(white);
	}

	[data-swiper-home] {
		height: $height;
		overflow: hidden;

		@media (max-width: bp(nav)) {
			height: 300px;
		}
	}

	h1 span[data-keyword-cont] {
		display: inline-flex;
		width: 425px;
		height: 55px;

		@media (max-width: bp(nav)) {
			width: 200px;
			height: 35px;
		}

		[data-swiper-keyword] {
			height: 100%;
			overflow: hidden;
		}

		.swiper-slide {
			justify-content: flex-start;
			padding: 10px 0;
      opacity: 0;
      &.swiper-slide-active {
        opacity: 1
      }
		}
	}

	h1 {
		@media (max-width: bp(nav)) {
			font-size: 3rem;
		}
	}

	@media (max-width: bp(nav)) {
		display: flex;
		flex-direction: column-reverse;
	}

	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.col {
			min-height: $height;

			@media (max-width: bp(nav)) {
				height: 300px;
			}

			&:nth-of-type(1) {
				width: 40%;
				height: 100%;
				display: block;
				background: color(accent);

				@media (max-width: bp(nav)) {
					width: 100%;
					display: none;
				}
			}

			&:nth-of-type(2) {
				width: 60%;
				height: 100%;
				display: block;
				background-size: cover;
				background-position: center right;
				min-height: $height;
				position: relative;

				@media (max-width: bp(nav)) {
					width: 100%;
					min-height: 300px;
				}

				&:after {
					content: "";
					width: 35vw;
					height: 100%;
					position: absolute;
					top: 0;
					left: -5px;
					background-image: linear-gradient(
						to right,
						rgba(23, 42, 69, 1),
						rgba(23, 42, 69, 0.75),
						rgba(23, 42, 69, 0)
					);
					z-index: 0;

					@media (max-width: bp(nav)) {
						width: 100%;
						height: 150px;
						bottom: 0;
						top: auto;
						left: 0;
						background-image: linear-gradient(
							to top,
							rgba(23, 42, 69, 1),
							rgba(23, 42, 69, 0.75),
							rgba(23, 42, 69, 0)
						);
					}
				}
			}
		}
	}

	.top-layer {
		z-index: 9;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		@include paddingTopBottom();

		@media (max-width: bp(nav)) {
			position: relative;
			height: auto;
			left: auto;
			top: auto;
			padding-top: 0;
			margin-top: -75px;
		}

		.flex-center {
			.col {
				&:nth-of-type(1) {
					width: 60%;
					align-items: flex-start;

					@media (max-width: bp(nav)) {
						width: 100%;
					}

					h1,
					p {
						text-align: left;

						span {
							font-style: italic;
						}
					}

					p {
						margin-left: $paddingMain;
						max-width: 550px;

						@media (max-width: bp(nav)) {
							margin-left: $paddingMainMobile;
						}
					}

					.btn {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 280px;
						padding: 15px;
						margin-left: $paddingMain;

						@media (max-width: bp(nav)) {
							margin-left: $paddingMainMobile;
						}

						span {
							font-size: 1.5rem;
						}

						.btm {
							display: flex;
							align-items: center;
							font-size: 2rem;
							margin-top: 5px;

							img,
							picture {
								width: 12px;
								height: auto;
								margin: 0 10px;
							}
						}
					}
				}

				&:nth-of-type(2) {
					width: 40%;

					@media (max-width: bp(nav)) {
						width: 100%;
					}
				}
			}
		}
	}
}

/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/

.swiper-section {
	.swiper-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.swiper-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.swiper-cont {
		overflow: hidden;
		position: relative;

		.swiper-button-prev {
			background: none;
			width: 50px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			left: 5px;

			&:after {
				content: "";
				width: 20px;
				height: 20px;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				position: absolute;
				top: 13px;
				left: 13px;
				transform: rotate(-135deg);
			}
		}

		.swiper-button-next {
			background: none;
			width: 50px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			right: 5px;

			&:after {
				content: "";
				width: 20px;
				height: 20px;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				position: absolute;
				top: 13px;
				right: 13px;
				transform: rotate(45deg);
			}
		}

		&.light {
			.swiper-button-next:after,
			.swiper-button-prev:after {
				border-color: #fff;
			}
		}

		.swiper-button-next.swiper-button-disabled,
		.swiper-button-prev.swiper-button-disabled {
			opacity: 0.5;
		}
	}

	.swiper-pagination {
		width: 100%;
		position: relative !important;
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 25px;

		.swiper-pagination-bullet {
			margin: 0 5px;
			opacity: 0.5;

			background-color: rgba(0, 0, 0, 255);
			border: 1px solid #fff;
			transition: all 0.5s;

			&.swiper-pagination-bullet-active {
				background-color: #fff;
				border: 1px solid #000;
				opacity: 1;
				width: 10px;
				height: 10px;
			}
		}
	}
}

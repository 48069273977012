.experience_reviews {
  padding: 6rem 0;

  .desc {
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
  }
  .rating-and-review {
    max-width: 750px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: bp(nav)) {
      flex-direction: column;
    }
    .rating {
      display: flex;
      align-items: center;
      .rating-description {
        padding: 1rem;
        font-size: 2.2rem;
        font-weight: 600;
      }
      .experiencecom {
        // width: 230px;
        .stars {
          width: 150px;
          .star-cont {
            overflow: hidden;
            img {
              width: 150px !important;
              height: auto;
            }
          }
        }
        p {
          margin: 0 !important;
        }
      }
    }
    .review {
      display: flex;
      align-items: center;
    
      .review-count {
        font-size: 1.8rem;
        padding: 1rem;
        margin-right: 1rem;
        .count {
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
}

.letter_section {
	@include paddingTopBottom();


  .small-cont {
		@media (max-width: bp(nav)) {

      width: calc(100% - 6rem);
    }


  }
	.headshot {
		width: 300px;
		height: auto;
		float: right;
		margin-left: #{$gap * 2};
    img {
      max-width: clamp(150px, 290px, 100%);
      width: 100%;
      height: auto;
    }

		@media (max-width: bp(nav)) {
			width: 150px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.eyebrow {
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 1.5px;
	}

	p.title3 {
		font-weight: 400;
		text-transform: inherit;
		line-height: calc(57 / 31);

		@media (max-width: bp(nav)) {
			font-size: 1.6rem;
		}
	}

	p img,
	picture {
		max-width: 80%;
		height: auto;
	}

	.signature {
		@include p-font-styles();
		p {
			margin-bottom: 0;
		}
	}
}

.page-id-372 {
	.general_section {
		.small-cont {
			.title3 {
				border-bottom: 2px solid color(grey, underlines);
				padding-bottom: $paddingMain;

				@media (max-width: bp(nav)) {
					padding-bottom: $paddingMainMobile;
					margin-bottom: 0;
				}
			}
		}
	}
}

.page-id-374 {
	.hero_section {
		h1 {
			max-width: 100%;
		}
	}
}

.home {
	.overlay_image_section {
		.btn {
			min-width: 380px;

			@media (max-width: bp(nav)) {
				min-width: inherit;
			}
		}
	}
}

.page-id-368 {
	.overlay_image_section {
		.btn {
			min-width: 380px;

			@media (max-width: bp(nav)) {
				min-width: inherit;
			}
		}
	}
}
